import React, { useState, useEffect } from "react";

import { ErrorBoundary } from "storybook-dashboard/errors";
import IssuesRAGPanel from "storybook-dashboard/dashboard/ragPanels/issues";
import IndicatorsRAGPanel from "storybook-dashboard/dashboard/ragPanels/indicators";
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar";
import InputValuesTable from "storybook-dashboard/dashboard/project/inputValues";
import DataExplorer from "storybook-dashboard/dashboard/components/dataExplorer";
import TotalEmissionsCount from "storybook-dashboard/dashboard/carbonComponents/totalEmissionsCount.js";
import ScopesDoughnut from "storybook-dashboard/dashboard/carbonComponents/scopesDoughnut.js";
import SupplierDataValuesCsv from "storybook-dashboard/dashboard/carbonComponents/supplierDataValuesCsv.js";
import SuppliersReported from "storybook-dashboard/dashboard/carbonComponents/suppliersReported.js";
import { ReportingPeriodRangePicker } from "storybook-dashboard/dashboard/components/reportingPeriodRangePicker";
import SupplierDataModal from "./dashboard/SupplierDataModal.js";
import { Hider } from "@action.sustainability/storybook-dashboard/utils";

import EmissionsLineChart from "./dashboard/emissionsChart.js";

import Im from "immutable";
import Octicon from "react-octicon";

const explorerOptionsProject = {
  // metric_values: {
  //     text: "Performance breakdown table",
  //     component: IndicatorPerformanceTable
  // },
  performance: {
    text: "Input values table",
    component: InputValuesTable,
  },
};

const explorerOptionsReportingPackage = {
  performance: {
    text: "Input values table",
    component: InputValuesTable,
  },
};

function SetShowSupplierDataButton({ hide, setShowSupplierDataModal }) {
  if (hide) {
    return null;
  }
  return (
    <button className="btn btn-link p-0" onClick={() => setShowSupplierDataModal(true)}>
      See supplier breakdown
    </button>
  );
}

export default function DashboardComponents({ projectId, refId, companyId, iconPath }) {
  let [selectedIssue, setSelectedIssue] = useState(null);
  let [selectedIndicatorsById, setSelectedIndicatorsById] = useState(Im.Map()); // A map of id: indicator data for selected indicators
  let [filters, setFilters] = useState(Im.Map());
  const [showSupplierDataModal, setShowSupplierDataModal] = useState(false);
  // Reset the selected indicators to none if the issue selection changes
  useEffect(() => {
    setSelectedIndicatorsById(Im.Map());
  }, [selectedIssue]);

  // Simplify the selection of indicators to just give a list of objects {uid, name}
  let selectedIndicators = selectedIndicatorsById.toList();

  //console.log("DashboardComponents", refId)

  return (
    <ErrorBoundary>
      <div className="row d-flex justify-content-start mb-3">
        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
          <ErrorBoundary>
            <TotalEmissionsCount
              projectId={projectId}
              refId={refId}
              supplierEmissions={<SetShowSupplierDataButton {...{ setShowSupplierDataModal }} />}
            />
          </ErrorBoundary>
          {/* If at reporting package level render the doughnut chart below the Total Emissions instead of Suppliers Reported */}
          {!(refId === "root" || !refId) ? (
            <ScopesDoughnut
              projectId={projectId}
              refId={refId}
              supplierEmissions={<SetShowSupplierDataButton {...{ setShowSupplierDataModal }} />}
            />
          ) : (
            <ErrorBoundary>
              <SuppliersReported projectId={projectId} refId={refId} companyId={companyId.substring(0, 8)} />
            </ErrorBoundary>
          )}
        </div>
        {/* Otherwise render the Doughnut chart besides the Total Emissions and Suppliers Reported components */}
        {!(refId === "root" || !refId) ? null : (
          <ErrorBoundary>
            <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
              <ScopesDoughnut
                projectId={projectId}
                refId={refId}
                supplierEmissions={<SetShowSupplierDataButton {...{ setShowSupplierDataModal }} />}
              />
            </div>
          </ErrorBoundary>
        )}

        <ErrorBoundary>
          <Hider hide={!(refId === "root" || !refId)}>
            <SupplierDataModal
              projectId={projectId}
              refId={refId}
              showSupplierDataModal={showSupplierDataModal}
              setShowSupplierDataModal={setShowSupplierDataModal}
            />
          </Hider>
        </ErrorBoundary>

        <ErrorBoundary>
          <div className="col-sm-12 col-xl-6">
            <div className="shadow p-3 my-3 bg-white rounded">
              <h5 className="card-title">Total emissions over time</h5>
              <p className="text-muted">Hover over the dots to see total emissions for year and % change</p>
              <EmissionsLineChart projectId={projectId} refId={refId} />
            </div>
          </div>
        </ErrorBoundary>
      </div>

      {/* <ErrorBoundary>
        <EmissionsLineChart projectId={projectId} refId={refId}/>
      </ErrorBoundary> */}

      <ReportingPeriodRangePicker filters={filters} setFilters={setFilters} />

      <ErrorBoundary>
        <IssuesRAGPanel
          projectId={projectId}
          refId={refId}
          iconPath={iconPath}
          selected={selectedIssue}
          setSelected={setSelectedIssue}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsRAGPanel
          projectId={projectId}
          refId={refId}
          iconPath={iconPath}
          issue={selectedIssue}
          selected={selectedIndicatorsById}
          setSelected={setSelectedIndicatorsById}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsBarChart
          projectId={projectId}
          refId={refId}
          indicators={selectedIndicators}
          filters={filters}
          period="year"
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <DataExplorer
          projectId={projectId}
          refId={refId}
          indicators={selectedIndicators}
          options={refId == "root" ? explorerOptionsProject : explorerOptionsReportingPackage}
        />
      </ErrorBoundary>
    </ErrorBoundary>
  );
}
