import React from "react";
import Im from "immutable";
import DataTable from "react-data-table-component";
import Octicon from "react-octicon";
import useApi, { useApis, usePostCache } from "storybook-dashboard/utils/fetching";
import { roundDecimals } from "storybook-dashboard/dashboard/charts/utils";
import { YearSelector, useYearSelector } from "storybook-dashboard/components/yearRangePicker";
import { generateAndDownloadCSV } from "storybook-dashboard/dashboard/carbonComponents/supplierDataValuesCsv";
import { getTenantConfig, getIndicatorUrl, ColumnName, getUnits } from "./utils";

const mapSupplierToValue = (indicatorData, key = "indicator_result") => {
  console.log(indicatorData?.toJS(), "csvData");
  let units = getUnits();
  return indicatorData?.reduce((acc, cur) => {
    return acc?.set(
      cur?.get("name"),
      // add the units ("£") to values that are not nulls
      key == "indicator_result" && cur?.get(key) !== null
        ? roundDecimals(cur?.get(key))
        : cur?.get(key) !== null
        ? `${units}${roundDecimals(cur?.get(key))}`
        : cur?.get(key) || null
    );
  }, Im.Map());
};

// Get the data for the tenant we are on
const requiredData = getTenantConfig();

export const SupplierDataModal = ({ showSupplierDataModal, setShowSupplierDataModal, projectId, refId }) => {
  const yearSelectorProps = useYearSelector("All time");
  const { dateRange } = yearSelectorProps;
  console.log({ dateRange });

  const year = dateRange.get("from_date") && dateRange.get("to_date") && Number(dateRange.get("from_date").slice(0, 4));
  const fetchFilters = {
    filters: {
      commit__reporting_period__endDate__gt: dateRange.get("from_date") || "2018-01-01T00:00:00", //"2022-01-01T00:00:00",
      commit__reporting_period__endDate__lte:
        dateRange.get("to_date") || `${new Date().getFullYear() + 1}-01-01T00:00:00`, //"2023-01-01T00:00:00",
    },
  };

  let _fetches = requiredData
    ?.map((value) => {
      let getUrlFunction = value.get("getUrl");
      let _id = value.get("id");
      return usePostCache(getUrlFunction(projectId, _id), fetchFilters);
    })
    ?.toJS();

  let { fetches, isLoading } = useApis(_fetches);

  // Initial reshaping of the data
  let data = requiredData?.map((value, key) => {
    let isIndicator = value.get("getUrl") == getIndicatorUrl;
    let _data = fetches[key].data;
    let imData = Im.fromJS(isIndicator ? _data?.refs : _data);
    return mapSupplierToValue(imData, isIndicator ? "indicator_result" : "value");
  });

  let dataByClient = data.reduce((acc, clientsData, key) => {
    clientsData?.map((value, name) => {
      let data = acc.get(name) || Im.Map({ name });
      acc = acc.set(name, data.set(key, value));
    });
    return acc;
  }, Im.Map());

  let rowData = [...dataByClient.toList()?.map((client) => Object.fromEntries(client.entries()))];

  const columns = [
    {
      name: "Supplier name",
      selector: (item) => item.name,
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="turnoverPerMilId" />,
      // sortable: true,
      selector: (item) => item.turnoverPerMilId,
      grow: 1,
      style: { paddingLeft: "5px" },
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="scopeOne" />,
      selector: (item) => item.scopeOne,
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="scopeTwo" />,
      selector: (item) => item.scopeTwo,
    },
    {
      name: <ColumnName fetches={requiredData} fetchKey="scopeThree" />,
      selector: (item) => item.scopeThree,
    },
    {
      name: (
        <ColumnName fetches={requiredData} fetchKey="turnover" showCumulative={true} year={year} showUnits={true} />
      ),
      selector: (item) => item.turnover,
    },
  ];

  return (
    <>
      <div
        className={`modal fade ${showSupplierDataModal ? "show" : ""}`}
        style={{ display: showSupplierDataModal ? "flex" : "none" }}
        tabIndex="-1"
        role="dialog"
      >
        <div
          role="document"
          style={{ width: "95%", height: "90%", zIndex: 99999, position: "fixed", top: 0, left: "2.5%", right: "2.5%" }}
        >
          <div
            className="modal-content "
            style={{
              width: "95%",
              height: "90%",
              zIndex: 99999,
              position: "fixed",
              top: 0,
              left: "2.5%",
              right: "2.5%",
              overflow: "scroll",
            }}
          >
            <div className="modal-header border-0">
              <h4 className="modal-title mt-3 w-100">Supplier Breakdown Data</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowSupplierDataModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <YearSelector {...yearSelectorProps} includeCustom={false} includeNone={true} />
              <button className="btn btn-link" onClick={() => generateAndDownloadCSV(data, year)}>
                <Octicon name="cloud-download" /> Export to csv
              </button>
              <DataTable
                columns={columns}
                data={rowData}
                // dense={false}
                // customStyles={tableCustomStyles}
              />
              <br />
            </div>
          </div>
        </div>
      </div>

      {showSupplierDataModal && <div className="modal-backdrop fade show" />}
    </>
  );
};

export default SupplierDataModal;
