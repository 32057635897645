import React from "react";
import useApi from "storybook-dashboard/utils/fetching"
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar";
import Im from "immutable"


const INDICATORS = Im.Set([
    "Total emissions (tCO2e)",
    "Annual total emissions per £1m turnover (tCO2e / GBP)",
    "Total emissions (tCO2e) / £1m turnover"
])

export default function EmissionsLineChart({projectId, refId}) {
 
    let { data } = useApi(`/api/dashboard/project/${projectId}/wpack/${refId}/rags`)

    let indicators = data?.get("indicators")
        ?.map((data, id) => data.set("id", id))
        ?.toList()
        ?.filter(i => INDICATORS.has(i.get("name")))
        ?.map(i => i.set("uid", i.get("uid") || i.get("id")))

    console.log("EmissionsLineChart", indicators?.toJS())

    if (!indicators || !indicators.size) return null;
    
    return (
        <IndicatorsBarChart
            projectId={projectId}
            refId={refId}
            indicators={indicators}
            filters={Im.Map()}
            period="year"
            chartType="line"
        />
    );
  }