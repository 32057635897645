import React, { useState, useEffect } from "react";
import Traec from "traec";
import { confirmProceed } from "traec-react/utils/sweetalert";
import { MetaSaveButton } from "./meta";
import { ErrorBoundary } from "traec-react/errors";
import { saveMeta } from "./meta";

const getPartners = (setState) => {
  let fetch = new Traec.Fetch(
    "company_dispatch",
    "post",
    {},
    {
      preDispatchHook: (action) => {
        action.fetchParams.body = {
          type: "GET_MATCHING_META",
          payload: { isPartner: "1" },
        };
        action.stateParams.stateSetFunc = (state, action) => {
          //console.log("Got response data from GET_MATCHING_META", action);
          let companyList = Traec.Im.fromJS(action.payload?.payload || Traec.Im.List());
          let companyMap = companyList.reduce((acc, cur) => acc.set(cur.get("uid"), cur), Traec.Im.Map());
          setState(companyMap);
          return state;
        };
        return action;
      },
    }
  );
  return fetch;
};

function Apportions(props) {
  let { apportions } = props;
  return apportions.map((apportion, i) => <Apportion {...props} key={i} index={i} apportion={apportion} />);
}

function Apportion({ partners, apportion, index, apportions, setApportions, disabled }) {
  let partnerId = apportion.get("companyId");
  let partner = partners.get(partnerId);

  return (
    <div className="row mt-1 mb-1">
      <div className="col">{partner?.get("name")}</div>
      <div className="col">{apportion?.get("percent")} %</div>
      <div className="col">
        {disabled ? null : (
          <a
            className="float-right"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setApportions(apportions.delete(index));
            }}
          >
            remove
          </a>
        )}
      </div>
    </div>
  );
}

function AddApportion(props) {
  let { partners, apportions, setApportions, hide } = props;

  if (hide) {
    return null;
  }

  let [value, setValue] = useState(0);
  let [tmpValue, setTmpValue] = useState(0);
  let [clientId, setClientId] = useState("");
  let [errors, setErrors] = useState("");

  let options = (partners || Traec.Im.Map())
    .toList()
    .sortBy((partner) => partner.get("name"))
    .map((partner, i) => (
      <option key={i} value={partner.get("uid")}>
        {partner.get("name")}
      </option>
    ))
    .unshift(<option key={-1} value={""}></option>);

  return (
    <ErrorBoundary>
      <div className="row">
        <div className="col">
          <select
            className="form-control form-control-sm"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
          >
            {options}
          </select>
        </div>
        <div className="col">
          <input
            className="d-inline form-control form-control-sm"
            style={{ width: "6em" }}
            value={value}
            type="text"
            onChange={(e) => {
              let value = e.target.value
              if (value.startsWith("0") && !(value[1] == ".")) {
                value = value.slice(1)
              }
              setValue(value || "0");
            }}
          />{" "}
          %
        </div>
        <div className="col">
          <button
            className="btn btn-primary btn-sm"
            onClick={(e) => {
              if (!partners.get(clientId)) {
                setErrors("Must select a valid partner");
                return;
              }
              let _value = parseFloat(value);
              if (!_value || _value <= 0 || _value > 100) {
                setErrors("Must provide a percentage between 0 and 100");
                return;
              }
              let existingClientIds = new Traec.Im.Set(apportions.map((i) => i.get("companyId")));
              if (existingClientIds.has(clientId)) {
                setErrors("Cannot add client twice");
                return;
              }
              setErrors("");
              setApportions(
                apportions.push(
                  Traec.Im.fromJS({
                    companyId: clientId,
                    percent: _value,
                  })
                )
              );
              setClientId("");
              setValue(0);
            }}
          >
            Add
          </button>
        </div>
      </div>
      {errors ? (
        <div className="row">
          <div className="col">
            <small id="errorText" className="form-text text-danger">
              {errors}
            </small>
          </div>
        </div>
      ) : null}
    </ErrorBoundary>
  );
}

export function SetMetaApportion(props) {
  let {
    hideAdmin,
    hideSave,
    saveMetaFetchProps,
    pushMetaFetchProps,
    metaJson,
    saveButtonText,
    disabled,
    isStaging,
    modalId
  } = props;

  // Ensure that we have something to start with for the meta-data
  metaJson = Traec.Im.fromJS(metaJson || Traec.Im.Map());
  //console.log("SetMetaApportion", Traec.Im.isImmutable(metaJson) ? metaJson.toJS() : metaJson);

  const [meta, setMeta] = useState(metaJson);
  const [state, setState] = useState({});
  const [partners, setPartners] = useState(Traec.Im.List());
  const [apportions, setApportions] = useState(metaJson.getInPath("apportions") || Traec.Im.List());
  const [pending, setPending] = useState(false);

  let total = apportions.reduce((a, c) => a + c.get("percent"), 0);
  hideSave = (total > 100) || (total < 0) || (apportions.size == 0);

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      state,
      setState,
      requiredFetches: [getPartners(setPartners)],
    });
  });

  const saveApportions = () => {
    setPending(true);
    let _meta = Traec.Im.fromJS({
      apportions,
      showApportions: false,
    });
    saveMeta(saveMetaFetchProps, _meta.toJS(), setPending);
    $(`#${modalId}`).modal("hide"); 
  }

  const onSaveHandler = (e) => {
    e.preventDefault();
    if (isStaging) { 
      saveApportions() 
    } else {
      confirmProceed({
        title: "Resubmit and approve report",
        text: "This will re-submit and approve the report and apportion data to clients.  Are you sure you would like to proceed?",
        onConfirm: () => saveApportions()
      });
    }
  };

  const pushHandler = (e, fieldName, includeValue) => {
    e.preventDefault();
    pushMeta(pushMetaFetchProps, fieldName, includeValue);
  };

  return (
    <ErrorBoundary>
      <>
        <AddApportion
          hide={hideAdmin || disabled}
          partners={partners}
          apportions={apportions}
          setApportions={setApportions}
        />
        <hr />
        <Apportions apportions={apportions} partners={partners} setApportions={setApportions} disabled={disabled} />
      </>
      <div className="my-3">
        {hideSave ? (
          <div className="row">
            <div className="col">
              <small id="errorText" className="form-text text-danger">
                The sum of apportionment percentages must be between 0 and 100 %
              </small>
            </div>
          </div>
        ) : (
          <MetaSaveButton
            hide={hideSave}
            pending={pending}
            onSaveHandler={onSaveHandler}
            saveButtonText={saveButtonText}
            disabled={disabled}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}
